import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import LogoEnglishMinute from '../assets/logoEM.png'

const useStyles = makeStyles((theme) => ({
  WelcomeContent: {
    marginTop: 25,
    textAlign: 'start',
  },
  WelcomeContentHeader: {
    fontSize: 40,
    margin: 0,
    color: '#003f91',
    textTransform: 'capitalize',
    [theme.breakpoints.down(710)]: {
      fontSize: 35,
    },
    [theme.breakpoints.down(680)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(630)]: {
      fontSize: 25,
    },
  },
  WelcomeContentText: {
    fontSize: 15,
    fontWeight: 600,
    color: 'black',
    textAlign: 'justify',
  },
  WelcomeContentButton: {
    cursor: 'pointer',
    width: '30%',
    minWidth: '200px !important',
    marginTop: '50px !important',
    textTransform: 'capitalize !important',
    backgroundColor: '#db5461 !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    // margin: 'auto !important',
  },
  BreakLine: {
    border: '2px #db5461 solid',
    borderRadius: 10,
    width: 170,
    margin: '10px 0px',
    marginBottom: 50,
  },
  LogoEnglishMinute: {
    width: 200,
    alignSelf: 'center',
  },
}))

const Welcome = (props) => {
  const stepFun = props.stepFun
  const classes = useStyles()

  const change = () => {
    stepFun(1)
  }

  return (
    <>
      <img
        src={LogoEnglishMinute}
        alt="logoEnglishMinute"
        className={classes.LogoEnglishMinute}
      />
      <div className={classes.WelcomeContent}>
        <h2 className={classes.WelcomeContentHeader}>
          Welcome to your Placement Test
        </h2>
        <hr className={classes.BreakLine} />
        <p className={classes.WelcomeContentText}>
          Afin d’évaluer votre niveau d’anglais actuel, nous vous invitons à
          répondre à ces{' '}
          <strong style={{ color: '#b8252d' }}>50 Questions</strong>. Vous aurez
          votre résultat de façon instantanée!
        </p>
        <p className={classes.WelcomeContentText}>
          Ready? Click on the{' '}
          <strong style={{ color: '#b8252d' }}>Start button</strong>
        </p>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button
            onClick={change}
            variant="contained"
            color="error"
            className={classes.WelcomeContentButton}
          >
            Start
          </Button>
        </div>
      </div>
    </>
  )
}

export default Welcome
