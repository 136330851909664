import React from 'react'
import { makeStyles } from '@mui/styles'
import LogoEnglishMinute from '../assets/logoEM.png'
import { Button } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  certificate: {
    marginTop: 25,
    textAlign: 'start',
  },
  LogoEnglishMinute: {
    width: 200,
    alignSelf: 'center',
  },
  certificateHeader: {
    fontSize: 40,
    margin: 0,
    color: '#003f91',
    textTransform: 'capitalize',
    [theme.breakpoints.down(710)]: {
      fontSize: 35,
    },
    [theme.breakpoints.down(680)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(630)]: {
      fontSize: 25,
    },
  },
  BreakLine: {
    border: '2px #db5461 solid',
    borderRadius: 10,
    width: 100,
    margin: '10px 0px',
  },
  certificateAverageText: {
    display: '-webkit-box',
    fontSize: 20,
    [theme.breakpoints.down(630)]: {
      fontSize: 17,
    },
    marginTop: 30,
  },
  certificateAverage: {
    margin: '0px 0px 0px 10px',
    fontWeight: 600,
    fontSize: 20,
    color: '#db5461',
  },
  certificateAnn: {
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: 35,
    marginBottom: 35,
    [theme.breakpoints.down(630)]: {
      fontSize: 17,
    },
  },
  WelcomeContentButton: {
    cursor: 'pointer',
    width: '30%',
    minWidth: '200px !important',
    marginTop: '10px !important',
    textTransform: 'capitalize !important',
    // color: '#18204c !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
  },
}))
const Congrats = (props) => {
  const classes = useStyles()
  const average = props.result.average
  // const average = 40
  const page = props.result.page
  const level = props.result.level
  const redirect = () => {
    window.location.href = page
  }
  React.useEffect(() => {
    setTimeout(() => {
      window.location.href = page
    }, 6000)
  })
  return (
    <>
      <img
        src={LogoEnglishMinute}
        alt="logoEnglishMinute"
        className={classes.LogoEnglishMinute}
      />
      <div className={classes.certificate}>
        <h2 className={classes.certificateHeader}>Good effort!</h2>
        <hr className={classes.BreakLine} />
        <p className={classes.certificateAverageText}>
          Votre résultat est:
          <div className={classes.certificateAverage}>
            {average.toString()}/50
          </div>
        </p>
        <p className={classes.certificateAverageText}>
          Votre niveau estimé est:
          <div className={classes.certificateAverage}>{level}</div>
        </p>
        <p className={classes.certificateAnn}>
          {average < 33 ? (
            <>
              Merci d’avoir complété votre test de placement. Vous serez
              redirigé vers votre cours dans un moment…
            </>
          ) : (
            <>
              Wow ! Vous avez bien réussi, le cours qui vous convient n'est pas
              encore disponible, inscrivez-vous et vous serez informé dès que
              nous le publierons…
            </>
          )}
        </p>

        <strong style={{ fontSize: 12, color: '#db5461' }}>
          Si vous n'êtes pas redirigé, appuyez sur le bouton Rediriger
        </strong>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button
            onClick={redirect}
            variant="outlined"
            //   color="error"
            className={classes.WelcomeContentButton}
          >
            Rediriger
          </Button>
        </div>
      </div>
    </>
  )
}

export default Congrats
