import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

const useStyles = makeStyles((theme) => ({
  TestContent: {
    marginTop: 25,
    textAlign: 'start',
    color: '#18204c',
  },
  TestContentHeader: {
    fontSize: 40,
    margin: 0,
    color: '#003f91',
    textTransform: 'capitalize',
    [theme.breakpoints.down(710)]: {
      fontSize: 35,
    },
    [theme.breakpoints.down(680)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(630)]: {
      fontSize: 25,
    },
  },
  WelcomeContentText: {
    fontSize: 15,
    fontWeight: 600,
    color: 'black',
  },
  TestContentButton: {
    cursor: 'pointer',
    width: '100%',
    marginTop: '0px !important',
    textTransform: 'capitalize !important',
  },
  TestNextContentButton: {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#003f91 !important',
    marginTop: '0px !important',
    textTransform: 'capitalize !important',
    fontWeight: '600 !important',
  },
  TestQuestion: {
    fontSize: 20,
    fontWeight: 500,
    margin: '15px 0px 10px 0px',
  },
  TestGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    marginBottom: 20,
  },
  answersGrid: {
    marginLeft: 35,
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    // [theme.breakpoints.down(860)]: {
    //   gridTemplateColumns: 'repeat(1, 1fr)',
    // },
    // [theme.breakpoints.down(620)]: {
    //   gridTemplateColumns: 'repeat(1, 1fr)',
    // },
    // [theme.breakpoints.down(550)]: {
    //   gridTemplateColumns: 'repeat(1, 1fr)',
    // },
  },
  BreakLine: {
    border: '2px #db5461 solid',
    borderRadius: 10,
    width: 135,
    margin: '10px 0px',
  },
  wrapVirt: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepper: {
    margin: '20px auto 0px auto',
    width: '50%',
    [theme.breakpoints.down(620)]: {
      // margin: 'auto',
      margin: '20px auto 0px 0px',
    },
  },
}))

const LevelOne = (props) => {
  // hook for the step
  const stepFun = props.stepFun
  //the data {questions and their answers}
  const data = props.data
  // the result hook
  const resFun = props.resFun
  // number of questions per page
  const devision = 10
  // style
  const classes = useStyles()
  // table on local storage
  var a =
    localStorage.getItem('answers') === null ||
    localStorage.getItem('answers') === undefined
      ? []
      : JSON.parse(localStorage.getItem('answers'))
  const [changed, setChanged] = React.useState(0)
  if (a.length === 0) {
    for (let i = 0; i < data.length; i++) {
      a[i] = 0
    }
    localStorage.setItem('answers', JSON.stringify(a))
  }
  // variable of the current progress
  const [etape, setEtape] = React.useState(0)
  const [answer, setAnswer] = React.useState(a)
  //evaluate the answers
  const evaluate = () => {
    var average = 0
    localStorage.removeItem('answers')
    for (let i = 0; i < data.length; i++) {
      answer[i] === data[i].correctAnswer
        ? (average = average + 1)
        : (average = average + 1 - 1)
    }
    var result = { average: average, level: null, page: null }
    if (average < 16) {
      result.level = 'Beginner'
      // window.location.href =
      //   'https://englishminute.getlearnworlds.com/home?site_template_id=63c56aea33aecf87ab0b81af'
      result.page = 'https://www.edugato.net/course/english-a101'
    } else {
      if (average < 25) {
        result.level = 'Elementary'
        result.page = 'https://www.edugato.net/course/general-english-a2'
      } else {
        if (average < 33) {
          result.level = 'Pre-intermediate'
          result.page = 'https://www.edugato.net/course/general-english-b1'
        } else {
          if (average < 40) {
            result.level = 'Intermediate'
            result.page = 'https://www.edugato.net/home'
          } else {
            if (average < 46) {
              result.level = 'Upper-intermediate'
              result.page = 'https://www.edugato.net/home'
            } else {
              result.level = 'Advanced'
              result.page = 'https://www.edugato.net/home'
            }
          }
        }
      }
    }
    console.log('result', result)
    resFun(result)
    // setAnswer([])
    stepFun(2)
  }
  let etapes = []
  for (let i = 0; i < data.length / devision; i++) {
    etapes[i] = i.toString()
  }
  // change the answer table in the local storage
  const modifyAnswer = (ind, ans) => {
    for (let i = 0; i < data.length; i++) {
      if (i === ind) {
        a[i] = ans
      } else {
        a[i] = answer[i]
      }
    }
    localStorage.removeItem('answers')
    localStorage.setItem('answers', JSON.stringify(a))
    setAnswer(a)
    setChanged(1)
  }

  const FormBeginRef = React.createRef()
  // const scrollToForm = () => {
  //   FormBeginRef.current.scrollIntoView({ behavior: 'smooth' })
  // }
  React.useEffect(() => {
    setChanged(0)
  }, [changed])
  // console.table(answer)
  return (
    <div className={classes.TestContent}>
      <div ref={FormBeginRef} />
      <h2 className={classes.TestContentHeader}>Cochez la bonne réponse:</h2>
      <hr className={classes.BreakLine} />
      {/* <BorderLinearProgress
        variant="determinate"
        value={50}
        // value={label === etape ? 100 : 0}
      /> */}
      {/* <div
        style={{
          width: '-webkit-fill-available',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-arround',
        }}
      >
        {etapes.map((label) => (
          <>{label}</>
        ))}
      </div> */}
      {/* {etapes.map((e,index) =>(
        
      ))} */}
      <div className={classes.TestGrid}>
        {data
          .slice(etape * devision, (etape + 1) * devision)
          .map((value, index) => {
            return (
              <div key={index}>
                <p className={classes.TestQuestion}>
                  {etape * devision + index + 1}. {value.q}
                </p>
                <div className={classes.answersGrid}>
                  {value.a.map((ans, i) => (
                    <div style={{ marginBottom: 15 }}>
                      <input
                        id={ans}
                        type="radio"
                        style={{ cursor: 'pointer' }}
                        checked={answer[10 * etape + index] === i + 1}
                        onClick={() => modifyAnswer(10 * etape + index, i + 1)}
                      />
                      <label for={ans} key={i}>
                        {ans}
                      </label>
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
      </div>{' '}
      {/* <h2 className={classes.TestContentHeader}>Test 1</h2> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 25,
        }}
      >
        <div style={{ width: '47%', color: '#b8252d' }}>
          <Button
            onClick={() => {
              if (etape !== 0) {
                setEtape(etape - 1)
                FormBeginRef.current.scrollIntoView({ behavior: 'smooth' })
              }
            }}
            className={classes.TestContentButton}
            variant="outlined"
            color="inherit"
          >
            Back
          </Button>
        </div>
        <div style={{ width: '47%' }}>
          {etape === data.length / devision - 1 ? (
            <Button
              variant="contained"
              onClick={evaluate}
              className={classes.TestNextContentButton}
              color="error"
            >
              Evaluate
            </Button>
          ) : (
            <Button
              onClick={() => {
                setEtape(etape + 1)
                FormBeginRef.current.scrollIntoView({ behavior: 'smooth' })
              }}
              variant="contained"
              className={classes.TestNextContentButton}
              color="error"
            >
              Next
            </Button>
          )}
        </div>
      </div>
      <Stepper activeStep={etape} className={classes.stepper}>
        {etapes.map((label) => (
          <Step key={label}>
            <StepLabel>{/* {label}/{data.length} */}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* <Button
        variant="outlined"
        onClick={evaluate}
        className={classes.TestContentButton}
      >
        Evaluate
      </Button> */}
    </div>
  )
}

export default LevelOne
