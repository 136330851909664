import './App.css'
import { useTheme, ThemeProvider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState, useRef } from 'react'
import Welcome from './components/Welcome'
import LevelTest from './components/LevelTest'
import { Slide } from '@mui/material'
import Navbar from './components/Navbar'
import { data } from './data/data'
import Congrats from './components/Congrats'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '80%',
    border: '1px #b1bee599 solid',
    borderRadius: 15,
    margin: '25px auto 0px auto',
    backgroundColor: 'white',
  },
  Container: {
    width: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 35px',
  },
}))

function App() {
  const theme = useTheme()
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const containerRef = useRef(null)
  const [result, setResult] = useState({ average: 0, level: '', page: '' })

  return (
    <div className="App" ref={containerRef}>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Slide
          direction="up"
          in={true}
          container={containerRef.current}
          timeout={1000}
        >
          <div className={classes.content}>
            <div className={classes.Container}>
              {/* <img
                src={LogoEnglishMinute}
                alt="logoEnglishMinute"
                className={classes.LogoEnglishMinute}
              /> */}
              <div>
                {step === 0 ? (
                  <Welcome stepFun={setStep} />
                ) : step === 1 ? (
                  <LevelTest stepFun={setStep} data={data} resFun={setResult} />
                ) : (
                  <Congrats result={result} />
                  // <h1>
                  //   Congrats you scored :D {result.average.toString()} you have
                  //   the level {result.level}
                  // </h1>
                )}
              </div>
            </div>
          </div>
        </Slide>
      </ThemeProvider>
    </div>
  )
}

export default App
