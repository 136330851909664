import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import LogoEnglishMinute from '../assets/logoEM.png'

const useStyles = makeStyles((theme) => ({
  navbar: {
    width: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 80px',
    backgroundColor: 'white',
    color: '#CB1B3C',
    justifyContent: 'space-between',
    boxShadow: '0 0 15px 0 rgb(0 0 0 / 5%)',
  },
  logoNav: {
    width: 80,
  },
  buttonNav: {
    width: 100,
    textTransform: 'capitalize !important',
  },
}))

const Navbar = () => {
  const classes = useStyles()
  return (
    <nav className={classes.navbar}>
      <img
        src={LogoEnglishMinute}
        alt="logoEnglishMinute"
        className={classes.logoNav}
      />
      <Button
        variant="outlined"
        className={classes.buttonNav}
        color="inherit"
        onClick={() => (window.location.href = 'https://www.edugato.net/home')}
      >
        Home
      </Button>
    </nav>
  )
}

export default Navbar
