export const Level1 = [
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 1,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 3,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 1,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 3,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
]

export const Level2 = [
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 1,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 3,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 1,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 3,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
  {
    q: 'this is a question',
    a: [
      'this is the first answer',
      'this is the second answer',
      'this is the third answer',
    ],
    correctAnswer: 2,
  },
]

export const data = [
  {
    q: 'I ________________ from Australia.',
    a: ['is', 'are', 'am', 'be'],
    correctAnswer: 3,
  },
  {
    q: 'This is my friend. _____________ name is Peter.',
    a: ['Her', 'Our', 'Yours', 'His'],
    correctAnswer: 4,
  },
  {
    q: 'Mike is ___________',
    a: [
      "my sister's friend",
      'friend my sister',
      'friend from my sister',
      "my sister friend's",
    ],
    correctAnswer: 1,
  },
  {
    q: 'My brother is __ artist',
    a: ['a', 'an', 'the', '--'],
    correctAnswer: 2,
  },
  {
    q: '___________20 desks in the classroom.',
    a: ['This it', 'There is', 'It is', 'There are'],
    correctAnswer: 4,
  },
  {
    q: 'Robert _____ romantic films.',
    a: ['likes not', "don't like", "doesn't like", "isn't likes"],
    correctAnswer: 3,
  },
  {
    q: "Sorry, I can't talk. I _____ right now.",
    a: ['driving', 'am driving', 'drive', 'drives'],
    correctAnswer: 2,
  },
  {
    q: 'She ____ at school last week.',
    a: ["didn't be", "wasn't", "weren't", "isn't"],
    correctAnswer: 2,
  },
  {
    q: 'I _______ the show last night.',
    a: ['liked', 'likes', 'like', 'liking'],
    correctAnswer: 1,
  },
  {
    q: '_________ a piece of cake?',
    a: ['Are you like', 'Do you like', 'Like you', 'Would you like'],
    correctAnswer: 4,
  },
  {
    q: 'The living room is ___________________ than the bedroom.',
    a: ['more big', 'biggest', 'more bigger', 'bigger'],
    correctAnswer: 4,
  },
  {
    q: 'The car is very old. We’re going ____________________ a new car soon.	',
    a: ['buying', 'to buy', 'to will buy', 'buy'],
    correctAnswer: 2,
  },
  {
    q: 'Jane is a vegetarian. She ____________________ meat.	',
    a: ['sometimes eats', 'always eats', 'never eats', 'likes to eat'],
    correctAnswer: 3,
  },
  {
    q: 'There aren’t ________________ buses late in the evening.',
    a: ['more', 'some', 'no', 'any'],
    correctAnswer: 4,
  },
  {
    q: 'The car park is _________________ the restaurant.',
    a: ['behind to', 'next to', 'in front', 'from opposite'],
    correctAnswer: 2,
  },
  {
    q: 'Sue ________________ shopping every day.',
    a: ['goes', 'is going', 'go', 'going'],
    correctAnswer: 1,
  },
  {
    q: 'They _________________ in the park when it started to rain heavily.',
    a: ['walked', 'were walking', 'walk', 'was walking'],
    correctAnswer: 2,
  },
  {
    q: '________________ seen fireworks before?',
    a: ['Did you ever', 'Are you ever', 'Do you ever', 'Have you ever'],
    correctAnswer: 4,
  },
  {
    q: 'We’ve been friends ____________________ many years.',
    a: ['since', 'from', 'during', 'for'],
    correctAnswer: 4,
  },
  {
    q: 'You _________________ pay for the tickets. They’re free.',
    a: ['have to', "don't have to", "don't need to", "doesn't have to"],
    correctAnswer: 3,
  },
  {
    q: 'Jeff was ill last week and he _________________ go out.',
    a: ["needn't", "can't", "mustn't", "couldn't"],
    correctAnswer: 4,
  },
  {
    q: 'These are the photos ________________ I took on holiday.',
    a: ['which', 'who', 'what', 'where'],
    correctAnswer: 1,
  },
  {
    q: 'We’ll stay at home if it _______________ this afternoon.',
    a: ['raining', 'rains', 'will rain', 'rain'],
    correctAnswer: 2,
  },
  {
    q:
      'He doesn’t smoke now, but he __________________ a lot when he was young.',
    a: ['was smoked', 'has smoked', 'used to smoke', 'smokes'],
    correctAnswer: 3,
  },
  {
    q: 'Mark plays football ___________________ anyone else I know.',
    a: ['more good than', 'better than', 'best than', 'as better as'],
    correctAnswer: 2,
  },
  {
    q:
      'I promise I __________________ you as soon as I’ve finished this cleaning.',
    a: ['will help', 'am helping', 'going to help', 'have helped'],
    correctAnswer: 1,
  },
  {
    q: 'This town ___________________ by lots of tourists during the summer.',
    a: ['visits', 'visited', 'is visiting', 'is visited'],
    correctAnswer: 4,
  },
  {
    q:
      'He said that his friends ____________ to speak to him after they lost the football match.',
    a: ['not want', "weren't", "didn't want", "aren't want"],
    correctAnswer: 3,
  },
  {
    q: 'How about _________________ to the cinema tonight?',
    a: ['going', 'go', 'to go', 'for going'],
    correctAnswer: 1,
  },
  {
    q:
      'Excuse me, can you ___________________ me the way to the station, please?',
    a: ['give', 'take', 'tell', 'say'],
    correctAnswer: 3,
  },
  {
    q:
      'A: I wasn’t interested in the performance very much. B: _______________.',
    a: ["I didn't, too.", 'Neither was I', 'No, I did', "So I wasn't"],
    correctAnswer: 2,
  },
  {
    q: 'Take a warm coat, _______________ you might get very cold outside.',
    a: ['otherwise', 'in case', 'so that', 'in order to'],
    correctAnswer: 1,
  },
  {
    q:
      '__________________ this great book and I can’t wait to see how it ends.	',
    a: ["I don't read", "I've read", "I've been reading", 'i read'],
    correctAnswer: 3,
  },
  {
    q: 'What I like more than anything else ___________________ at weekends.',
    a: ['playing golf', 'to play golf', 'is playing golf', 'is play golf'],
    correctAnswer: 3,
  },
  {
    q:
      'She ________________ for her cat for two days when she finally found it in the garage.',
    a: ['looked', 'had been looked', 'had been looking', 'were looking'],
    correctAnswer: 3,
  },
  {
    q:
      'We won’t catch the plane _________________ we leave home now! Please hurry up!',
    a: ['if', 'providing that', 'except', 'unless'],
    correctAnswer: 4,
  },
  {
    q:
      'If I hadn’t replied to your email, I___________________ here with you now.',
    a: ["can't be", "wouldn't be", "won't be", "haven't been"],
    correctAnswer: 2,
  },
  {
    q:
      'Do you think you ___________________ with my mobile phone soon? I need to make a call',
    a: ['finish', 'are finishing', 'will have finished', 'are finshed'],
    correctAnswer: 3,
  },
  {
    q:
      'I don’t remember mentioning __________________ dinner together tonight.',
    a: ['go for', 'you going for', 'to go for', 'going for'],
    correctAnswer: 4,
  },
  {
    q: 'Was it Christopher Columbus ______________ America?',
    a: [
      'who discovered',
      'discovered',
      'that discovered',
      'who was discovering',
    ],
    correctAnswer: 1,
  },
  {
    q:
      'You may not like the cold weather here, but you’ll have to ________________, I’m afraid.',
    a: ['tell it off', 'sort itself out', 'put up with it', 'put it off'],
    correctAnswer: 3,
  },
  {
    q: 'It’s cold so you should __________________ on a warm jacket.',
    a: ['put', 'wear', 'dress', 'take'],
    correctAnswer: 1,
  },
  {
    q: 'Paul will look ______________ our dogs while we’re on holiday.',
    a: ['at', 'for', 'into', 'after'],
    correctAnswer: 4,
  },
  {
    q: 'She ___________________ a lot of her free time reading',
    a: ['does', 'has', 'spends', 'makes'],
    correctAnswer: 3,
  },
  {
    q: 'Hello, this is Simon. Could I ___________________ to Jane, please?',
    a: ['say', 'tell', 'call', 'speak'],
    correctAnswer: 4,
  },
  {
    q: 'They’re coming to our house ___________________ Saturday.',
    a: ['in', 'at', 'on', 'with'],
    correctAnswer: 3,
  },
  {
    q: 'I think it’s very easy to ___________ debt these days.',
    a: ['go into', 'become', 'do down to', 'get into'],
    correctAnswer: 4,
  },
  {
    q: 'Come on! Quick! Let’s get _____________!	',
    a: ['highlight', 'cracking', 'massive', 'with immediate effect'],
    correctAnswer: 2,
  },
  {
    q: 'I phoned her ____________ I heard the news.',
    a: ['minute', 'during', 'by the time', 'the moment'],
    correctAnswer: 4,
  },
  {
    q: 'I feel very ____________. I’m going to go to bed!',
    a: ['nap', 'asleep', 'sleepy', 'sleeper'],
    correctAnswer: 3,
  },
]
